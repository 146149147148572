<template>
    <div class="relative">
        <span class="inline-block w-full rounded-md shadow-sm relative">
            <button
                @click="openSelectMenu"
                type="button"
                class="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-8 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                <div class="flex items-center space-x-3">
                    <span class="block truncate">{{ filterName }} - {{ activeOptionName }}</span>
                </div>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-600 pointer-events-none">
                    <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </button>
        </span>

        <div
            ref="selectMenu"
            class="select-menu transition-opacity duration-75 ease-in absolute mt-1 w-full rounded-md bg-white shadow-lg">
            <ul role="listbox" class="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                <li
                    v-for="(option, idx) in options"
                    :key="idx"
                    @click="() => onOptionSelected(option.value)"
                    :id="`listbox-item-${idx}`"
                    role="option"
                    class="text-gray-900 cursor-default select-none hover:bg-gray-200 relative py-1 pl-3 pr-9">
                    <div class="flex items-center space-x-3">
                        <span
                            class="block truncate"
                            :class="{
                                'font-normal': option.value !== selectedOption,
                                'font-semibold': option.value === selectedOption,
                            }"
                        >{{ option.name }}</span>
                    </div>

                    <span
                        v-if="option.value === selectedOption"
                        class="absolute inset-y-0 right-0 flex items-center pr-4">
                        <doneSvg class="h-4 fill-current" />
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TutorialFilter',

    props: {
        filterName: {
            type: String,
            required: true,
        },

        options: {
            type: Array,
            required: true,
        },

        activeOption: {
            type: String,
            required: true,
        },
    },

    data() {
        const selectedOption = this.activeOption;
        return {
            selectMenuOpen: false,
            selectedOption,
        };
    },

    watch: {
        activeOption(newValue) {
            this.selectedOption = newValue;
        },
    },

    computed: {
        activeOptionName() {
            return this.options.find((o) => o.value === this.selectedOption).name;
        },
    },

    methods: {
        afterCloseSelectMenu() {
            document.removeEventListener('click', this.closeSelectMenu);
            this.selectMenuOpen = false;
        },

        closeSelectMenu(evt) {
            if (evt !== false) {
                try {
                    if (this.$refs.selectMenu.contains(evt.srcElement)) return;
                } catch (ex) { return; }
            }
            this.$refs.selectMenu.classList.remove('active');
            this.afterCloseSelectMenu();
        },

        openSelectMenu(evt) {
            evt.stopImmediatePropagation();
            if (this.selectMenuOpen) return;
            this.$refs.selectMenu.classList.add('active');
            document.addEventListener('click', this.closeSelectMenu);
            this.selectMenuOpen = true;
        },

        onOptionSelected(value) {
            this.selectedOption = value;
            this.$emit('filter-changed', this.filterName, value);
            this.closeSelectMenu(false);
        },
    },

    components: {
        doneSvg: () => import('@/assets/img/done.svg'),
    },
};
</script>

<style lang="postcss" scoped>
.select-menu {
    opacity: 0;
    pointer-events: none;

    &.active {
        opacity: 1;
        pointer-events: auto;
    }
}
</style>
